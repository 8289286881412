@import "~client_core/product/detail";

.product-detail {
    .ratings {
        margin-bottom: $spacer-sm;
        @include media-breakpoint-up(lg) {
            margin-bottom: $spacer-xl;
        }
    }
    .prices {
        display: flex;
        justify-content: center;
    }
    .promotions {
        margin-top: 0;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $tertiary-font;
        font-size: $paragraph-font-size-small;
        letter-spacing: $default-text-letter-spacing;
        .promotion {
            margin-top: calc(#{$spacer} / 2);
            color: $pdp-promo-color;
        }
    }
    .justify-content-center {
        .swatch-circle {
            margin: calc(#{$pdp-color-swatch-spacing} / 2);
        }
    }
    .availability {
        &.product-availability {
            padding-top: $spacer;
            @include media-breakpoint-up(lg) {
                padding-top: $spacer-xl;
            }
        }
        .availability-container {
            justify-content: center;
            .non-input-label {
                display: none;
            }
            li {
                font-size: $pdp-attribute-label-font-size;
            }
            > * {
                order: 2;
            }
            i {
                order: 1;
                padding-right: $spacer-sm;
            }
        }
    }
    .pdp-in-store-inventory {
        .non-input-label {
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
            > * {
                order: 2;
            }
            i {
                order: 1;
            }
        }
    }
    .js_credit_message_pdp {
        display: flex;
        justify-content: center;
        padding-left: $spacer;
        @include media-breakpoint-up(lg) {
            padding-left: $spacer * 2; //paypal button has a text-left set so this offsets it to be more 'centered'
        }
    }
    .product-data {
        .product-headlinedescription {
            margin: $spacer;
        }
        .size-fit-links {
            justify-content: center;
            i {
                display: none;
            }
        }
    }
    .main-content-group {
        .size-fit-links {
            &:not(:hover):not(:active) {
                background-color: map-get($bg, secondary);
            }
            a {
                font-weight: $button-font-weight;
            }
        }
        .ratings {
            margin-bottom: 0px;
            margin-left: $spacer-sm;
        }
    }
}

.suggested-products-slider {
    border-top: 1px solid map-get($border, tertiary);
}

// BAZAARVOICE OVERRIDES
.page {
    [data-bv-show=rating_summary] {
        .bv_main_container {
            justify-content: center;
        }
    }
}