@import "~client_core/account/account";

// Dashboard pages
.account-page {
    [class*="back-to-account-link"] {
        white-space: nowrap;
        &:not([class*="icon"]) {
            @include back-button-icon();
        }
    }
}