@import "~client_core/mixins";

$special-button-offset: 4px;
$special-button-border-width: 1px;

@mixin button-special() {
    @include button(secondary);
    background-color: transparent;
    padding: calc(#{$button-primary-padding-top} + #{$special-button-offset}) calc(#{$button-primary-padding-right} + #{$special-button-offset}) calc(#{$button-primary-padding-bottom} + #{$special-button-offset}) calc(#{$button-primary-padding-left} + #{$special-button-offset});
    overflow: hidden;
    border-width: 0px;

    .dark-theme & {
        color: $button-primary-color;
        background-color: transparent;
        &:hover {
            color: $button-primary-dark-theme-color-hover;
        }
        &:active {
            background-color: $button-secondary-background-active;
        }
    }

    &.disabled, &[disabled] {
        background-color: transparent;
        color: $button-disabled-content;
        border-width: 0px;

        &:not([class*="icon"]), //overriding other button css
        &[class*="icon"] {
            &:after,
            &:before {
                opacity: 1;
                box-shadow:0px 0px 0px $special-button-border-width $button-disabled-background;
                position: absolute;
            }
        }
    }

    &:not([class*="icon"]), //overriding other button css
    &[class*="icon"] {
        &:after,
        &:before {
            transition: width $transition-speed, height $transition-speed, opacity $transition-speed * 1.5;
            opacity: 1;
            box-shadow:0px 0px 0px $special-button-border-width map-get($border, accent);
            position: absolute;
            transform-origin: center center;
        }

        &:after {
            width: calc(100% - (#{$special-button-border-width} * 2));
            height: calc(100% - (#{$special-button-border-width} * 2));
        }

        &:before {
            width: calc(100% - #{$special-button-offset * 2} - (#{$special-button-border-width} * 2));
            height: calc(100% - #{$special-button-offset * 2} - (#{$special-button-border-width} * 2));
        }

        &:active,
        &:hover {
            &:before {
                width: calc(100%);
                height: calc(100%);
            }
            &:after,
            &:before {
                opacity: 0;
            }
        }
    }
}

@mixin back-button-icon() {
    @include icon($icon-arrow-left, before);
    padding-left: calc(#{$button-padding-left} + #{$button-icon-size});
    &:before {
        opacity: 1;
        left: 0;
        font-size: #{$button-icon-size};
        transform: translateX(#{$spacer}) translateY(-1px);
    }
}

@mixin delete-card-button($absolute: true) {
    //local mixin vars
    $delete-card-icon-padding: 4px;
    $delete-card-border-width: 1px;

    @include icon($product-card-remove-icon, before, $product-card-remove-icon-size);
    font-family: $body-font;
    font-feature-settings: $variable-settings;
    border: $delete-card-border-width solid $product-card-border-color;
    border-radius: $product-card-remove-icon-size;
    font-size: 0;
    @if $absolute {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: calc(#{$card-header-padding-top - $delete-card-icon-padding - $delete-card-border-width});
        margin-right: calc(#{$card-header-padding-right - $delete-card-icon-padding - $delete-card-border-width});
        margin-bottom: calc(#{$card-header-padding-bottom - $delete-card-icon-padding - $delete-card-border-width});
        margin-left: calc(#{$card-header-padding-left - $delete-card-icon-padding - $delete-card-border-width});
    }
    cursor: pointer;
    z-index: 1;
    background-color: $product-card-remove-icon-background;
    padding: $delete-card-icon-padding;
    height: calc(#{($product-card-remove-icon-size + $delete-card-border-width) * 2});
    width: calc(#{($product-card-remove-icon-size + $delete-card-border-width) * 2});
    transition: background-color $transition-speed ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height:$product-card-remove-icon-size;
    &:hover {
        background-color: $product-card-border-color;
    }
    //HARTMANN CHANGE: the icon set renders a little smaller than usual so need to offset it a pixel
    &:before {
        position: relative;
        left: 1px;
    }
}