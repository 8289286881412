@import "~client_core/components/footer";

footer {
    .prefooter {
        background-color: $footer-background;
        color: map-get($content, inverse);
    }

    .footer-container {
        padding-top: $spacer;
        @include media-breakpoint-up(md) {
            padding-top: calc(#{$spacer} * 2);
        }
    }

    .store {
        padding-top: 0;
    }

    .back-to-top {
        margin: -5px 5px 5px;
        padding: 0;
        .fa-circle {
            box-shadow: none;
        }
    }

    .footer-item {
        &.collapsible-sm {
            .title {
                @include banner(4);
                color: $footer-title-font-color;
                &:after {
                    top: 22px;
                }
            }
            .title,
            .content {
                    background: transparent;
            }
            &.active > .content-asset > .title:after {
                color: inherit; //fix for sudo-dark-theme footer on HM
            }
        }
        ul {
            padding-top: calc(#{$spacer} * 2);
        }
    }

    .social {
        .social-links,
        .social-links li {
            justify-content: flex-end;
            display: flex;
            a {
                color: map-get($content, inverse-alt);
            }
        }
    }

    .email-signup-form {
        .form-control {
            color: $footer-link-color;
            &::placeholder {
                color: $footer-link-color;
            }
            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px map-get($bg, tertiary) inset;
                -webkit-text-fill-color: map-get($content, primary);
                border: none;
            }
        }
        .inline-btn,
        .inline-btn:hover,
        .inline-btn:focus {
            border-color: map-get($border, accent-alt);
        }
        .email-description {
            color: $footer-link-color;
            margin-bottom: 8px;
        }

        .btn {
            @include icon($icon-arrow-top, after, 16px, map-get($content, inverse));
            &::after {
                transform: rotate(90deg);
            }
            &::before {
                content:'';
            }
        }
    }
}

