@import "~client_core/components/breadcrumb";

.breadcrumb {
    font-family: $breadcrumb-font-family;
    .breadcrumb-item {
        a {
            text-transform: $breadcrumb-item-h1-text-transform;
        }
    }
}
