@import "~client_core/components/wishlist";


[class*="product-list-enhancements-modal"] .btn-secondary {
    @include button(primary);
}
.product-list-enhancements-modal-show-lists-toggle-lists .toggle.selected div {
    color: inherit;
}
.product-list-enhancements-create-list-btn.btn-secondary {
    @include button-special();
}
