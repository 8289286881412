@import "~client_core/components/header";

.header {
    .minicart {
        .minicart-quantity {
            transform: $header-minicart-translate-mobile;
            @include media-breakpoint-up(md) {
                transform: $header-minicart-translate;
            }
            font-family: $header-minicart-font-family;
        }
        .minicart-icon {
            &:before {
                font-size: $header-minicart-icon-font-size-mobile;
                @include media-breakpoint-up(md) {
                    font-size: $header-minicart-icon-font-size;
                }
            }
        }
    }
}
html:not(.sticky-header):not(.sticky-header-lock) .transparent-header:not(.no-transparent-nav-mobile):not(.mobile-transparent-nav-off) {
    .header-nav {
        &.transparent-nav {
            &.transparent-nav-light {
                &:not(.fixed) {
                    .minicart {
                        .minicart-quantity {
                            color: $header-transparent-text-color-dark;
                        }
                    }
                }
            }
        }
    }
}

.main-menu {
    .logo-center & {
        border-top: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;
        border-bottom: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;
    }
}
