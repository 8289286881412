@import "~client_core/components/slider";

// Thumbnails
.slider-thumbnails {
    .slide {
        &.tns-nav-active {
            padding: $slider-thumbnail-border-active-width;
        }
    }
}
.slider-container {
    &:not([data-slider-controls-position='top']) {
        .tns-controls {
            button {
                margin: 0 #{$spacer * .75};
                border-radius: 50%;
            }
        }
    }
}