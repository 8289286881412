@import "~client_core/components/formFields";

// Checkboxes
.custom-checkbox {
    .custom-control-input {
        &:checked ~ .custom-control-label {
            &:after {
                width: calc(#{$radiocheck-button-size + $radiocheck-icon-offset});
                top: calc(#{$radiocheck-icon-offset} * -1);
                font-size: $radiocheck-icon-size;
            }
        }
    }
}