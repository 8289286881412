@import "~client_core/components/card";

.card {
    p {
        &:not([class*='font-size-']):not([class*='p--']) {
            span {
                &:not([class*='font-size-']):not([class*='p--']):not([class*='label']) {
                    font-size: $default-text-font-size;
                }
            }
        }
    }
}