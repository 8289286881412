@import "~client_core/components/collapsibleItem";

.accordion {
    .card {
        &:first-child {
            border-top: 1px solid $border-color;
        }
        .card-header {
            [type="button"] {
                .accordion-cart-promo & {
                    &::after {
                        left: 0;
                    }
                }
            }
        }
    }
}