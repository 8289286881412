@import "~client_core/helperClasses";
// Create button classes, e.g. .btn-primary
.btn {
    &-special {
        @include button-special();
    }
    &-tertiary {
        &:not([class*="icon"]) {
            @include icon($icon-arrow-right-thin, after);
            &:after {
                opacity: 1;
                right: 0;
                transform: translateX(0);
            }
            &[class*="btn-"]:not(.title) {
                &:after {
                    font-size: $button-sm-icon-size;
                    transform: translateX(calc(#{$spacer} * -1)) translateY(-1px);
                }
            }
        }
    }
    &[class*="btn-"] {
        &[class*="icon-"] {
            &.icon-large {
                &.icon-before,
                &:not(.icon-after) {
                    padding-left: calc(#{$button-tertiary-padding-left} + (#{$button-icon-size-extra-large} * 1.5));
                    &:before {
                        font-size: $button-icon-size-extra-large;
                    }
                }
                &.icon-after {
                    padding-right: calc( #{$button-tertiary-padding-right} + (#{$button-icon-size-extra-large} * 1.5));
                    &:after {
                        font-size: $button-icon-size-extra-large;
                    }
                }
            }
        }
    }
}

$hero-gradient-dark: rgba($black,0.35) 0%, rgba($black,0.5) 33%, rgba($black,0) 67%;
$hero-gradient-light: rgba($off-white,0.35) 0%, rgba($off-white,0.5) 33%, rgba($off-white,0) 67%;

[class*="fade-to-"] {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hero-text {
        z-index: 2;
    }
}

$bps: '', sm, md, lg, xl;
$dirs: (
    top: 0,
    right: 90,
    bottom: 180,
    left: 270
);

@each $bp in $bps{
    @each $dir, $deg in $dirs{
        .fade-to-#{$dir} {
            @if $bp == '' {
                &:before {
                    background: linear-gradient(#{$deg}deg, $hero-gradient-dark);
                    @at-root .fade-light#{&} {
                        background: linear-gradient(#{$deg}deg, $hero-gradient-light);
                    }
                }
            }
            @else {
                @include media-breakpoint-up($bp) {
                    &--#{$bp} {
                        &:before {
                            background: linear-gradient(#{$deg}deg, $hero-gradient-dark);
                            @at-root .fade-light#{&} {
                                background: linear-gradient(#{$deg}deg, $hero-gradient-light);
                            }
                        }
                    }
                }
            }
        }
    }
}