@import "~client_core/components/productTiles";

.product-tile {
    .tile-preimage{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $product-tile-pre-post-image-margin-bottom;
        height: $product-tile-pre-post-image-default-height;
        .badge-product-container.badge-image-over {
            position: unset;
            margin: 0px;
            order: 2;
            @include banner($product-tile-badge-banner-value);
        }
        .product-list-enhancements-toggle-product {
            &.product-tile-wishlist-icon {
                margin-left: 0px;
                order: 1;
            }
        }
    }
    .color-swatches {
        .swatch-color-count {
            font-family: $product-tile-swatch-color-count-font;
        }
    }
    .quickview {
        font-family: $tertiary-font;
        .quickview-icon-label {
            .product-grid-small & {
                font-size: $default-text-font-size;
            }
        }
        .quickview-icon:before {
            position: relative;
            top: 2px;
        }
    }
    .tile-postimage {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacer-sm;
    }
    .price {
        font-family: $product-tile-price-font;
        line-height: 1;
    }
    .promotion {
        text-align: center;
        font-family: $tertiary-font;
        font-size: $paragraph-font-size-small;
        letter-spacing: $default-text-letter-spacing;
        line-height: 1;
    }
    .ratings {
        margin-top: $product-tile-rating-margin-top;
    }
}
