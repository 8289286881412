@import "~client_core/cart/cart";

.cart-page {
    // product lineitem cards
    .product-info {
        label {
            @include banner(4);
        }
    }
}
