@import "~client_core/product/grid";

// Grid header
.grid-header,
.content-grid-header {
    .product-sort {
        @include banner(4);
        @include media-breakpoint-up(lg) {
            margin-top: calc(#{$spacer-xl} / 2);
        }

        select {
            @include banner(4);
        }

        label,
        select {
            font-size: $paragraph-font-size-small;
            text-transform: $product-filter-sort-order-text-transform;
        }
    }
}

.filter-bar {
    .filter-value {
        button {
            span {
                @include banner(5);
            }
        }
    }
}

//Horizontal Refinements - Desktop ONLY
@include media-breakpoint-up(lg) {
    .horizontal-multipledropdowns-filter-btn {
        > span {
            font-weight: $product-refinement-horizontal-multipledropdowns-btn-font-weight;
        }
    }
}